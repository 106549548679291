import "./App.css";
import Editor, { useMonaco } from "@monaco-editor/react";
import { useEffect, useMemo, useState, useRef } from "react";
import useDebounce from "./debounce";
import ResizePanel from "react-resize-panel";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Tab from "@mui/material/Tab";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { deflateSync, inflateSync } from "fflate";
import ListItemIcon from "@mui/material/ListItemIcon";
import ErrorIcon from "@mui/icons-material/Error";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import { TabContext, TabList } from "@mui/lab";
import { decode, encode } from "./base64";

const theme = createTheme();

const prefix = "#";

const INIT = `import { useStore, $, component$, Host } from '@builder.io/qwik';

export const HelloWorld = component$(() => {
  return $(() => {
    return (
      <Host>
        <Counter/>
      </Host>
    );
  });
});

export const Counter = component$(() => {
  const state = useStore({
    count: 0
  });

  return $(() => {
    return (
      <Host>
        <button on$:click={() => state.count}>
          {state.count}
        </button>
      </Host>
    );
  });
});

`;

const getInitialState = () => {
  const defaults = {
    code: INIT,
    transpile: true,
    minify: "simplify",
    entryStrategy: "hook",
    view: "bundles",
  };
  let fragment = getFragment();
  if (fragment !== "") {
    const data = inflateSync(new Uint8Array(decode(fragment)));
    const json = new TextDecoder().decode(data);
    return {
      ...defaults,
      ...JSON.parse(json),
    };
  }
  return defaults;
};

const getFragment = () => {
  const fragment = window.location.hash;
  if (fragment.startsWith(prefix)) {
    return fragment.slice(prefix.length);
  }
  return "";
};

export default function App() {
  const editorRef = useRef(null);
  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
  }
  const monaco = useMonaco();
  const state = useMemo(() => getInitialState(), []);
  const [versions, setVersions] = useState([]);
  const [version, setVersion] = useState(state.version);
  const optimizerPromise = useMemo(async () => {
    if (version) {
      const { createOptimizer } = await import(
        /* webpackIgnore: true */ `https://cdn.jsdelivr.net/npm/@builder.io/qwik@${version}/optimizer.mjs`
      );
      const optimizerPromise = await createOptimizer();
      return optimizerPromise;
    }
  }, [version]);

  useEffect(() => {
    async function load() {
      const { versions, selected } = await getVersions();
      setVersions(versions);
      if (!version) {
        setVersion(selected);
      }
    }
    load();
    // eslint-disable-next-line
  }, []);

  const [code, setCode] = useState(state.code);
  const [minify, setMinify] = useState(state.minify);
  const [entryStrategy, setEntryStrategy] = useState(state.entryStrategy);
  const [transpile, setTranspile] = useState(state.transpile);
  const [modules, setModules] = useState([]);
  const [bundles, setBundles] = useState([]);

  const [diagnostics, setDiagnostics] = useState([]);
  const [view, setView] = useState(state.view);

  const debouncedCode = useDebounce(code, 200);

  useEffect(() => {
    async function load() {
      const compiler = await optimizerPromise;
      if (!compiler) {
        return;
      }
      const opts = {
        rootDir: "/internal/project",
        transpile,
        minify,
        entryStrategy: {
          type: entryStrategy,
        },
        explicityExtensions: true,
        sourceMaps: false,
        input: [
          {
            path: "input.tsx",
            code: debouncedCode,
          },
        ],
      };
      const result = await compiler.transformModules(opts);
      setModules(result.modules);
      setDiagnostics(result.diagnostics);

      if (monaco) {
        for (const diagnostic of result.diagnostics) {
          monaco.editor.setModelMarkers(editorRef.current.getModel(), "test", [
            {
              startLineNumber: diagnostic.code_highlights[0].loc.start_line,
              startColumn: diagnostic.code_highlights[0].loc.start_col,
              endLineNumber: diagnostic.code_highlights[0].loc.end_line,
              endColumn: diagnostic.code_highlights[0].loc.end_col,
              message: diagnostic.message,
              severity: "error",
            },
          ]);
        }
      }
      if (transpile) {
        const inputOptions = {
          plugins: [
            {
              resolveId(importee, importer) {
                if (!importer) return importee;
                if (importee[0] !== ".") return false;
                const id = importee + ".js";
                const found = result.modules.find((p) => id.includes(p.path));
                if (found) {
                  return found.path;
                }
                return {
                  id: importee,
                  external: true,
                };
              },
              load: function (id) {
                const found = result.modules.find((p) => id.includes(p.path));
                if (found) {
                  return found.code;
                }
                return null;
              },
            },
          ],
          onwarn(warning) {
            console.warn(warning);
          },
        };
        inputOptions.input = "input.js";

        try {
          const generated = await (
            await window.rollup.rollup(inputOptions)
          ).generate({
            format: "es",
          });
          console.log(generated.output);
          setBundles(
            generated.output.map((o) => ({
              path: o.fileName,
              code: o.code,
              isEntry: o.isDynamicEntry,
            }))
          );
        } catch (error) {
          console.error(error);
        }
      }
    }
    load();
  }, [
    debouncedCode,
    optimizerPromise,
    minify,
    entryStrategy,
    transpile,
    monaco,
  ]);

  useEffect(() => {
    const state = JSON.stringify({
      code: debouncedCode,
      minify,
      entryStrategy,
      transpile,
      view,
      version,
    });
    const data = deflateSync(new TextEncoder().encode(state), {
      mem: 8,
    });
    window.location.hash = prefix + encode(data);
  }, [debouncedCode, minify, entryStrategy, transpile, view, version]);

  const codes = view === "bundles" && transpile ? bundles : modules;
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <header>
          <a href="https://github.com/builderio/qwik" className="logo">
            <img
              alt="Qwik logo"
              src="https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2F667ab6c2283d4c4d878fb9083aacc10f"
            />
          </a>
          Optimizer Playground
          <select
            value={version}
            label="Version"
            onChange={(ev) => setVersion(ev.target.value)}
          >
            {versions.map((v) => (
              <option key={v} value={v}>
                {v}
              </option>
            ))}
          </select>
          <nav className="top-menu">
            <a
              className="link"
              target="_blank"
              rel="noreferrer"
              href="https://github.com/BuilderIO/qwik/tree/main/integration"
            >
              Docs
            </a>
          </nav>
        </header>
        <div className="panel">
          <ResizePanel
            direction="e"
            style={{
              width: "50%",
            }}
          >
            <Editor
              width="100%"
              height="100%"
              language="typescript"
              onMount={handleEditorDidMount}
              beforeMount={(monaco) => {
                monaco.languages.typescript.typescriptDefaults.setCompilerOptions(
                  {
                    jsx: true,
                  }
                );

                monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions(
                  {
                    noSemanticValidation: true,
                    noSyntaxValidation: true,
                  }
                );
              }}
              options={{
                scrollBeyondLastLine: false,
                minimap: {
                  enabled: false,
                },
              }}
              onChange={(value) => {
                setCode(value);
              }}
              value={code}
            />
          </ResizePanel>
          <div className="output-code">
            <h2>Options</h2>
            <Stack direction="row" spacing={2} marginTop>
              <FormControl fullWidth>
                <InputLabel id="minification-label">Minification</InputLabel>
                <Select
                  labelId="minification-label"
                  id="minification-select"
                  value={minify}
                  label="Minification"
                  onChange={(_, v) => setMinify(v.props.value)}
                >
                  <MenuItem value="minify">minify</MenuItem>
                  <MenuItem value="simplify">simplify</MenuItem>
                  <MenuItem value="none">none</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="strategy-label">Entry strategy</InputLabel>
                <Select
                  labelId="strategy-label"
                  id="strategy-select"
                  value={entryStrategy}
                  label="Entry stategy"
                  onChange={(_, v) => setEntryStrategy(v.props.value)}
                >
                  <MenuItem value="single">single</MenuItem>
                  <MenuItem value="hook">hook</MenuItem>
                  <MenuItem value="component">component</MenuItem>
                  <MenuItem value="smart">smart</MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Switch
                    checked={transpile}
                    onChange={(ev, v) => setTranspile(v)}
                  />
                }
                label="Transpile"
              />
            </Stack>

            {diagnostics.length > 0 && (
              <>
                <CardContent className="diagnostics">
                  <Typography variant="h5" component="div">
                    Diagnostics
                  </Typography>
                  <List>
                    {diagnostics.map((diagnostic) => (
                      <ListItem disablePadding>
                        <ListItemIcon>
                          <ErrorIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={diagnostic.message}
                          secondary={
                            <>
                              Line{" "}
                              {diagnostic.code_highlights[0].loc.start_line}
                            </>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </>
            )}

            <h2>Output</h2>

            {transpile && (
              <TabContext value={view}>
                <TabList
                  aria-label="lab API tabs example"
                  onChange={(_, value) => setView(value)}
                >
                  <Tab label="Modules" value="modules" />
                  <Tab label="Bundles" value="bundles" />
                </TabList>
              </TabContext>
            )}
            <ul>
              {codes.map((mod) => (
                <li
                  className={mod.isEntry ? "is-entry" : undefined}
                  key={mod.path}
                >
                  <button
                    className="href"
                    onClick={() => {
                      const element = document.getElementById(mod.path);
                      if (element && element.scrollIntoView) {
                        element.scrollIntoView();
                      }
                    }}
                  >
                    {mod.path}
                  </button>{" "}
                  [{formatSize(mod.code.length)}]
                  {mod.isEntry && "  (entry point)"}
                </li>
              ))}
            </ul>

            {codes.map((mod) => {
              return (
                <Paper
                  id={mod.path}
                  className="chunk"
                  key={mod.path}
                  elevation={2}
                >
                  <h3>{mod.path}</h3>
                  <SyntaxHighlighter language="javascript" style={docco}>
                    {mod.code}
                  </SyntaxHighlighter>
                </Paper>
              );
            })}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

const formatSize = (bytes) => {
  if (bytes < 1000) {
    return `${bytes} B`;
  }
  return `${(bytes / 1000).toFixed(1)} KB`;
};

const getVersions = async () => {
  const FALLBACK = "0.0.14-2";
  try {
    const res = await fetch(
      "https://data.jsdelivr.com/v1/package/npm/@builder.io/qwik"
    );
    if (res.ok) {
      const json = await res.json();
      const latest = json.tags.latest;
      const first = flattenVersion(0, 0, 14);
      const versions = json.versions.filter((v) => {
        const [major, minor, rest] = v.split(".");
        const [patch] = rest.split("-");
        return flattenVersion(major, minor, patch) >= first;
      });

      return {
        versions,
        selected: versions.find((v) => v === latest) ?? FALLBACK,
      };
    }
  } catch (e) {
    console.error(e);
  }
  return {
    versions: [FALLBACK],
    selected: FALLBACK,
  };
};

const flattenVersion = (major, minor, patch) => {
  major = parseInt(major, 10);
  minor = parseInt(minor, 10);
  patch = parseInt(patch, 10);
  return major * 10000000 + minor * 1000 + patch;
};
